import Fade from "react-reveal/Fade";

function MessageSucces(props) {
  return (
    <>
      <Fade duration="1500">
        <div className="p-4 bg-white rounded-lg shadow-lg md:p-8 lg:p-10 xl:p-16 ">
        <h1 className="text-center font-bold text-slate-700 text-[24px] lg:text-[30px] mb-8">
            Bedankt voor uw bericht!
          </h1>
          <div className="text-center">
            <label className="font-semibold text-[18px] lg:text-[20px]">
              E-mailadres
            </label>
            <p>{props.email}</p>

            <div className="py-6">
              <label className="font-semibold text-[18px] lg:text-[20px]">
                Naam
              </label>
              <p>{props.name}</p>
            </div>

            <label className="font-semibold text-[18px] lg:text-[20px]">
              Bericht
            </label>
            <textarea
              className="block w-full p-2 mt-1 border-2 border-gray-400 rounded-lg resize-none h-52"
              readOnly
            >
              {props.message}
            </textarea>
          </div>
          <div className="flex justify-center">
            <h1 className="text-center font-bold text-adhocBlauw text-[18px] lg:text-[24px] mt-8">
              Wij zullen zo snel mogelijk reageren
            </h1>
          </div>
        </div>
      </Fade>
    </>
  );
}

export default MessageSucces;
