import { useState } from "react";
import Fade from "react-reveal/Fade";
import OfferteSucces from "../components/OfferteSucces";

function Offerte() {
  const [selectedWerk, setSelectedWerk] = useState([]);
  const [bedrijfsnaam, setBedrijfsnaam] = useState("");
  const [voornaam, setVoornaam] = useState("");
  const [tussenvoegsel, setTussenvoegsel] = useState("");
  const [achternaam, setAchternaam] = useState("");
  const [email, setEmail] = useState("");
  const [telefoonnummer, setTelefoonnummer] = useState("");
  const [privacyAccepted, setPrivacyAccepted] = useState(false);
  const [omschrijving, setOmchrijving] = useState("");

  const [bedrijfsnaamError, setBedrijfsnaamError] = useState(false);
  const [voornaamError, setVoornaamError] = useState(false);
  const [achternaamError, setAchternaamError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [telefoonnummerError, setTelefoonnummerError] = useState(false);
  const [werkzaamhedenError, setWerkzaamhedenError] = useState(false);
  const [privacyError, setPrivacyError] = useState(false);

  const [formError, setFormError] = useState(false);
  const [formFilled, setFormFilled] = useState(false);

  const werkzaamheden = [
    "Servicedesk medewerker",
    "Systeembeheer",
    "Werkplekbeheer",
    "Cybersecurity consultancy",
    "App development",
    "Web development",
    "Anders",
  ];

  function handleFormSubmit() {
    let errorValue = false;

    const setError = (errorState, value) => {
      errorState(value);
      errorValue = true;
    };

    !bedrijfsnaam.length > 0 ? setError(setBedrijfsnaamError, true) : setBedrijfsnaamError(false);
    !voornaam.length > 0 ? setError(setVoornaamError, true) : setVoornaamError(false);
    !achternaam.length > 0 ? setError(setAchternaamError, true) : setAchternaamError(false);
    !email.length > 0 ? setError(setEmailError, true) : setEmailError(false);
    !telefoonnummer.length > 0 ? setError(setTelefoonnummerError, true) : setTelefoonnummerError(false);
    selectedWerk.length === 0 ? setError(setWerkzaamhedenError, true) : setWerkzaamhedenError(false);
    !privacyAccepted ? setError(setPrivacyError, true) : setPrivacyError(false);

    if (!errorValue) {
      sendOfferte()
      setFormFilled(true);
      window.scrollTo(0, 0);
    } else {
      setFormError(true);
    }
  }

  function displayWerkzaamheden() {
    return werkzaamheden.map((werkzaamheid, index) => (
      <div className="flex items-center gap-3 mb-4 align-middle">
        <div>
        <input
          className="w-4 h-4 rounded-xl cursor-pointer accent-adhocBlauw saturate-[130%]"
          onClick={() => handleWerkzaamheidSelection(werkzaamheid)}
          type="checkbox"
          index={index}
        />
        </div>
        <label className="text-[18px] lg:text-[20px]">
          {werkzaamheid}
        </label>
      </div>
    ));
  }

  function displaySelectedWerkzaamheden() {
    return selectedWerk.map((werkzaamheid) => (
      <div className="transition ease-in-out md:hover:scale-110">
        <Fade>
          <div className="p-10 mb-8 shadow-md bg-adhocBlauw rounded-xl">
            <h1 className="text-center text-white font-semibold text-[18px] lg:text-[20px] md:w-[250px]">
              {werkzaamheid}
            </h1>
          </div>
        </Fade>
      </div>
    ));
  }

  function handleWerkzaamheidSelection(werkzaamheid) {
    const copy = [...selectedWerk];

    if (!copy.includes(werkzaamheid)) {
      copy.push(werkzaamheid);
      setSelectedWerk(copy);
      return;
    }

    const index = copy.indexOf(werkzaamheid);
    copy.splice(index, 1);
    setSelectedWerk(copy);
  }

  function getName() {
    return (
      voornaam + " " + (tussenvoegsel.length > 0 ? tussenvoegsel + " " : "") +  achternaam
    );
  }

  function getData() {
    return {
      subject: "Offerte van " + bedrijfsnaam,
      body:
        "<h1>Offerte</h1><p>Bedrijf: <span>" +
        bedrijfsnaam +
        "<span><p/><p>Naam: <span>" +
        getName() +
        "<span><p/> <p>E-mail: <span>" +
        email +
        "<span><p/><p>Tel.nummer: <span>" +
        telefoonnummer +
        "<span><p/><p>Services: <span>" +
        selectedWerk +
        "<span><p/><p>Beschrijving: <span>" +
        omschrijving +
        "<span><p/>",
    };
  }

  const sendOfferte = () => {
    let data = getData();

    fetch("https://adhocmailer.herokuapp.com/Email", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });

    console.log("gelukt")
  };

  if (formFilled) {
    return (
      <>
        <OfferteSucces
          company={bedrijfsnaam}
          name={getName()}
          email={email}
          phone={telefoonnummer}
          selected={selectedWerk}
          message={omschrijving}
        />
      </>
    );
  }

  return (
    <div className="px-4 pb-4 bg-adhocBlauw md:pb-16">
      <Fade>
        <h1 className="py-8 font-bold text-[30px] md:text-[32px] lg:text-[36px] text-center text-white">
          Offerte aanvragen
        </h1>
        <div className="gap-10 mx-auto md:w-fit xl:grid xl:grid-cols-2">
          {/* Contact gegevens */}
          <div className="gap-8 px-8 py-10 bg-white shadow-lg md:px-20 sm:py-16 xl:col-span-1 rounded-xl sm:shadow-xl">
            <h1 className="text-center font-bold text-slate-700 text-[24px] lg:text-[30px]">
              Contact gegevens
            </h1>
            <div className="items-center justify-center gap-16 md:flex sm:mt-8">
              <div className="text-center">
                <div className="py-4 md:pb-8">
                  <label className="font-semibold text-[18px] lg:text-[20px]">
                    Bedrijfsnaam*
                  </label>
                  {bedrijfsnaamError ? (
                    <p className="text-red-500"> Verplicht veld </p>
                  ) : null}
                  <input
                    className="block p-2 mx-auto mt-1 border-2 border-gray-400 rounded-lg md:mx-0"
                    id="company"
                    type="text"
                    placeholder="Uw Bedrijfsnaam"
                    value={bedrijfsnaam}
                    onChange={(e) => {
                      setBedrijfsnaam(e.target.value);
                    }}
                  />
                </div>
                <label className="font-semibold text-[18px] lg:text-[20px] pb-4">
                  Voornaam*
                </label>
                {voornaamError ? (
                  <p className="text-red-500"> Verplicht veld </p>
                ) : null}
                <input
                  className="block p-2 mx-auto mt-1 mb-4 border-2 border-gray-400 rounded-lg md:mx-0"
                  id="firstname"
                  type="text"
                  placeholder="Uw voornaam"
                  value={voornaam}
                  onChange={(e) => {
                    setVoornaam(e.target.value);
                  }}
                />
                <label className="font-semibold text-[18px] lg:text-[20px] pb-4">
                  Tussenvoegsel
                </label>
                <input
                  className="block p-2 mx-auto mt-1 mb-4 border-2 border-gray-400 rounded-lg md:mx-0"
                  id="infix"
                  type="text"
                  placeholder="Uw tussenvoegsel"
                  value={tussenvoegsel}
                  onChange={(e) => {
                    setTussenvoegsel(e.target.value);
                  }}
                />
                <label className="font-semibold text-[18px] lg:text-[20px] pb-4">
                  Achternaam*
                </label>
                {achternaamError ? (
                  <p className="text-red-500"> Verplicht veld </p>
                ) : null}
                <input
                  className="block p-2 mx-auto mt-1 border-2 border-gray-400 rounded-lg md:mx-0"
                  id="lastname"
                  type="text"
                  placeholder="Uw achternaam"
                  value={achternaam}
                  onChange={(e) => {
                    setAchternaam(e.target.value);
                  }}
                />
              </div>
              <div className="mt-4 text-center md:mt-8">
                <label className="font-semibold text-[18px] lg:text-[20px]">
                  E-mailadres*
                </label>
                {emailError ? (
                  <p className="text-red-500"> Verplicht veld </p>
                ) : null}
                <input
                  className="block p-2 mx-auto mt-1 mb-4 border-2 border-gray-400 rounded-lg md:mx-0"
                  id="email"
                  type="email"
                  placeholder="Uw e-mailadres"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                />
                <label className="font-semibold text-[18px] lg:text-[20px]">
                  Telefoonnummer*
                </label>
                {telefoonnummerError ? (
                  <p className="text-red-500"> Verplicht veld </p>
                ) : null}
                <input
                  className="block p-2 mx-auto mt-1 border-2 border-gray-400 rounded-lg md:mx-0"
                  id="phone"
                  type="text"
                  placeholder="Uw telefoonnummer"
                  value={telefoonnummer}
                  onChange={(e) => {
                    setTelefoonnummer(e.target.value);
                  }}
                />
              </div>
            </div>
          </div>

          {/* Werkzaamheden */}
          <div className="px-8 py-10 my-4 bg-white shadow-lg md:px-20 sm:my-10 xl:my-0 sm:py-16 xl:col-span-1 rounded-xl sm:shadow-xl">
            <h1 className="text-center font-bold text-slate-700 text-[24px] lg:text-[30px]">
              Werkzaamheden
            </h1>
            <h1 className="text-center font-semibold text-slate-700 text-[18px] lg:text-[20px]">
              Naar welke werkzaamheden bent u opzoek?*
            </h1>
            {werkzaamhedenError ? (
              <p className="text-center text-red-500"> Verplicht veld </p>
            ) : null}
            <div className="gap-10 mx-auto mt-8 w-fit xl:pt-8">
              {displayWerkzaamheden()}
            </div>
          </div>

          {/* Overzicht/verzenden */}
          <div className="col-span-2 px-8 py-10 pb-16 bg-white shadow-lg md:px-20 sm:py-16 rounded-xl sm:shadow-xl">
            <h1 className="text-center font-bold text-slate-700 text-[24px] lg:text-[30px]">
              Overzicht
            </h1>
            <div className="flex flex-col mt-10 xl:flex-row justify-evenly">
              <div className="self-start order-last w-full mx-auto text-center md:w-1/2 xl:order-first">
                <label className="font-semibold text-[18px] lg:text-[20px]">
                  Omschrijving
                </label>
                <textarea
                  className="w-full p-2 mt-2 border-2 border-gray-400 rounded-lg resize-none h-52"
                  placeholder="Uw omschrijving"
                  value={omschrijving}
                  onChange={(e) => {
                    setOmchrijving(e.target.value);
                  }}
                />
                <div className="flex flex-col mt-8">
                  <div className="py-2">
                    {privacyError ? (
                      <p className="text-red-500"> Verplicht veld </p>
                    ) : null}
                    <input
                    className="accent-adhocBlauw saturate-[130%]"
                      type="checkbox"
                      onChange={() => {
                        setPrivacyAccepted(!privacyAccepted);
                      }}
                    />
                    <label className="pl-2">
                      Ik accepteer de{" "}
                      <a
                        className="font-semibold underline hover:text-adhocBlauw200"
                        href="/privacy"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        privacyverklaring
                      </a>
                      *
                    </label>
                  </div>
                  {formError ? (
                    <p className="text-red-500">
                      {" "}
                      Niet alle velden zijn ingevuld{" "}
                    </p>
                  ) : null}
                  <button
                    className="mt-2 px-8 py-2 text-[18px] md:text-[20px] font-semibold text-white rounded-[100px] max-w-fit mx-auto bg-gradient-to-br from-adhocBlauw  via-adhocBlauw200  to-adhocDonkerBlauw bg-size-200 bg-pos-0 hover:bg-pos-100 transition-all duration-300"
                    onClick={handleFormSubmit}
                  >
                    Verzenden
                  </button>
                </div>
              </div>
              <div className="flex justify-center items-center lg:w-[500px] mb-8 xl:mb-20">
                {selectedWerk.length === 0 ? (
                  <>
                    <h1 className="text-center md:text-end xl:ml-14 font-semibold text-slate-700 text-[18px] lg:text-[20px]">
                      U heeft nog geen werkzaamheden geselecteerd.
                    </h1>
                  </>
                ) : (
                  <>
                    <h1>{displaySelectedWerkzaamheden()}</h1>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </Fade>
    </div>
  );
}

export default Offerte;
