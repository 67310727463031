import { FcCheckmark } from "react-icons/fc";

function CheckBulletPoint(props) {
  return (
    <div
      className={`flex items-center mx-4 border-b ${
        props.conditionalBorder ? "lg:border-b-0" : ""
      } ${props.noBorder ? "border-b-0" : ""}`}
    >
      <div>
        <FcCheckmark />
      </div>
      <p className={"pl-4 py-4"}>{props.text}</p>
    </div>
  );
}

export default CheckBulletPoint;
