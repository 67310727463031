import { FaLinkedin } from "react-icons/fa";

function Footer() {
  return (
    <footer className="w-full py-4 mt-auto bg-gray-50">
      <div className="text-center">
        <h1 className="text-transparent bg-clip-text bg-gradient-to-tl from-adhocDonkerBlauw to-adhocBlauw font-bold text-[22px] md:text-[26px]">
          AD HOC IT SERVICES
        </h1>
        <h1 className="font-semibold text-gray-500">KVK-nummer: 87169509</h1>
        <div className="flex justify-center gap-8 pt-2 text-gray-400">
          <a
            href="https://www.linkedin.com/company/ad-hoc-it-services/"
            target="_black"
            rel="noreferrer"
          >
            <FaLinkedin
              className="transition ease-in-out cursor-pointer md:hover:scale-105"
              size={40}
            />
          </a>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
