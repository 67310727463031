import Fade from "react-reveal/Fade";

function OfferteSucces(props) {
  function displaySelectedWork() {
    return props.selected.map((selection) => <p>{selection}</p>);
  }

  return (
    <div className=" bg-adhocBlauw">
      <div className="flex justify-center py-8 mx-4 text-center md:mx-0">
        <Fade duration="1500">
          <div className="px-6 pb-16 bg-white rounded-lg shadow-lg md:w-fit pt-14 md:px-10 lg:px-14 xl:px-20 ">
            <h1 className="text-center font-bold text-slate-700 text-[24px] lg:text-[30px] py-8">
              Bedankt voor uw aanvraag.
            </h1>

            <div>
              <div className="items-baseline justify-center md:flex gap-28 md:mb-8">
                <div>
                  <div className="">
                    <label className="font-semibold text-[18px] lg:text-[20px]">
                      Naam
                    </label>
                    <p>{props.name}</p>
                  </div>

                  <div className="pt-6">
                    <label className="font-semibold text-[18px] lg:text-[20px]">
                      E-mailadres
                    </label>
                    <p>{props.email}</p>
                  </div>

                  <div className="pt-6">
                    <label className="font-semibold text-[18px] lg:text-[20px]">
                      Telefoonnummer
                    </label>
                    <p>{props.phone}</p>
                  </div>
                </div>

                <div className="pt-6 md:pt-0">
                  <label className="font-semibold text-[18px] lg:text-[20px] pt-6">
                    Bedrijf
                  </label>
                  <p>{props.company}</p>

                  <div className="py-6">
                    <label className="font-semibold text-[18px] lg:text-[20px]">
                      Werkzaamheden
                    </label>
                    <p>{displaySelectedWork()}</p>
                  </div>
                </div>
              </div>

              <label className="font-semibold text-[18px] lg:text-[20px]">
                Omschrijving
              </label>
              <textarea
                className="block w-full p-2 mt-1 border-2 border-gray-400 rounded-lg resize-none h-52"
                readOnly
              >
                {props.message.length > 0
                  ? props.message
                  : "Geen omschrijving."}
              </textarea>
            </div>
            <div className="flex justify-center">
              <h1 className="text-center font-bold text-adhocBlauw  text-[18px] lg:text-[24px] pt-8">
                Wij nemen binnen 48 uur contact met u op.
              </h1>
            </div>
          </div>
        </Fade>
      </div>
    </div>
  );
}

export default OfferteSucces;
