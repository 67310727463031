import { TbPointFilled } from "react-icons/tb";

function RoundBulletPoint(props) {
  return (
    <div className="flex items-center mx-4">
      <div>
        <TbPointFilled className="text-slate-800" />
      </div>
      <p className={"pl-4 py-2"}>{props.text}</p>
    </div>
  );
}

export default RoundBulletPoint;
