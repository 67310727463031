import Amnesty from "../resources/partners/amnesty_bg.png";
import Pfzw from "../resources/partners/pfzw.png";
import Nielsen from "../resources/partners/nielsen.png";
import Fade from "react-reveal/Fade";

function Sponsors() {
  const width = window.innerWidth;

  const getContent = () => {
    return (
      <>
        <h1 className=" text-white text-[24px] lg:text-[30px] py-8 sm:py-14">
          Deze partners gingen u voor
        </h1>
        <div className="gap-8 lg:flex lg:justify-center">
          <div className="p-8 px-16 mx-auto mb-6 bg-white shadow-lg rounded-xl lg:mb-0">
            <img
              className="mx-auto w-[200px] md:w-[250px] lg:w-[300px]"
              src={Nielsen}
              alt="Nielsen"
            />
          </div>
          <div className="p-8 px-16 mx-auto mb-6 bg-white shadow-lg rounded-xl lg:mb-0">
            <img
              className="mx-auto w-[200px] md:w-[250px] lg:w-[300px] rounded-lg"
              src={Amnesty}
              alt="Amnesty"
            />
          </div>
          <div className="p-8 px-16 mx-auto bg-white shadow-lg rounded-xl">
            <img
              className="mx-auto w-[200px] md:w-[250px] lg:w-[300px]"
              src={Pfzw}
              alt="PFZW"
            />
          </div>
        </div>
      </>
    );
  };
  return (
    <div className="px-4 pb-8 text-center sm:pb-16 rounded-t-xl bg-adhocBlauw">
      {width > 764 ? <Fade>{getContent()}</Fade> : getContent()}
    </div>
  );
}

export default Sponsors;
