import Hero from "../components/Hero";
import Promo from "../components/Promo";
import Sponsors from "../components/Sponsors";
import Over from "../resources/home/over.png";
import { HiOutlineArrowRight } from "react-icons/hi";
import { Link } from "react-router-dom";

function Home() {
  return (
    <>
      <Hero />
      <Promo />
      <div className="justify-center gap-20 my-24 sm:my-28 lg:my-48 lg:flex">
        <div className="mx-4 sm:mx-0">
          <img
            src={Over}
            alt="Ali Jirrie"
            className="w-[400px] md:w-[500px] xl:w-[600px] rounded-2xl mx-auto"
          />
        </div>

        <div className="flex flex-col mx-12 mt-10 text-center sm:mx-44 lg:text-left lg:mx-0 lg:my-auto lg:w-1/3">
          <p className="font-times-new-roman text-[20px] lg:text-[22px] mb-4">
            “Met ons ervaren en hechte IT-team streven we ernaar om hoogwaardige
            diensten te leveren en onze klanten te helpen bij het beveiligen van
            hun digitale omgeving.”
          </p>

          <p>-Ali Jirrie, Oprichter Ad Hoc IT services</p>
          <div className="mx-auto mt-8 lg:mt-12 ">
            <Link to={"/over"}>
              <button className="flex items-center text-adhocBlauw border-2 border-adhocBlauw bg rounded-[100px] px-4 py-1 font-semibold transition-all ease-in-out duration-500  hover:bg-adhocBlauw hover:text-white">
                Lees meer over ons{" "}
                <HiOutlineArrowRight className="ml-2" size={20} />
              </button>
            </Link>
          </div>
        </div>
      </div>
      <Sponsors />
    </>
  );
}

export default Home;
