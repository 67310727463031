import React, { useState } from "react";
import Logo from "../resources/logo_nav.png";
import { AiOutlineClose, AiOutlineMenu } from "react-icons/ai";
import { BsChevronDown } from "react-icons/bs";
import { Link } from "react-router-dom";
import Fade from "react-reveal/Fade";

function Navbar() {
  const [mobileNav, setMobileNav] = useState(false);
  const [serviceDropdown, setserviceDropdown] = useState(false);

  const handleMobileNav = () => {
    setMobileNav(!mobileNav);
  };

  const closeMobileNav = () => {
    setMobileNav(false);
    setserviceDropdown(false);
  };

  return (
    <div className="sticky top-0 z-10 flex items-center justify-between w-full h-24 px-4 bg-white md:bg-opacity-75 md:backdrop-blur-xl md:px-18 lg:px-20">
      <Link to="/">
        <img
          className="w-[75px] cursor-pointer duration-300 md:hover:scale-95 focus:-translate-x-5"
          src={Logo}
          alt="AdHoc IT"
        />
      </Link>
      <ul className="items-baseline hidden gap-10 text-center md:flex">
        <div>
        <li
          className="p-4 text-[20px] text-slate-700 cursor-pointer flex items-start h-full gap-1"
          onMouseOver={() => setserviceDropdown(true)}
          onMouseOut={() => setserviceDropdown(false)}
        >
          Services
          <span
            className={`mt-2 ${ serviceDropdown ? "rotate-180" : "" } transition-transform duration-200`}
          >
            <BsChevronDown />
          </span>
          <Fade duration="600">
            <div
              className={
                serviceDropdown
                  ? "absolute right-[450px] top-[75px] flex flex-col p-4 rounded-xl shadow-md bg-white gap-2"
                  : "hidden"
              }
            >
              <Link
                to="services/software-ontwikkelen"
                onClick={() => setserviceDropdown(false)}
              >
                <p className="cursor-pointer md:hover:text-adhocBlauw md:hover:underline">
                  Software Development
                </p>
              </Link>
              <Link
                to="services/ict-servicedesk"
                onClick={() => setserviceDropdown(false)}
              >
                <p className="cursor-pointer md:hover:text-adhocBlauw md:hover:underline">
                  ICT servicedesk
                </p>
              </Link>
              <Link
                to="services/professioneel-advies"
                onClick={() => setserviceDropdown(false)}
              >
                <p className="cursor-pointer md:hover:text-adhocBlauw md:hover:underline">
                  Cybersecurity Consultancy
                </p>
              </Link>
            </div>
          </Fade>
        </li>
        </div>
        <Link to="over">
          <li className="p-4 text-[20px] text-slate-700 cursor-pointer md:hover:text-adhocBlauw md:hover:underline">
            Over ons
          </li>
        </Link>
        <Link to="/contact">
          <li className="p-4 text-[20px] text-slate-700 cursor-pointer md:hover:text-adhocBlauw md:hover:underline">
            Contact
          </li>
        </Link>

        <Link to="offerte">
          <li className="text-[20px] my-4 px-4 font-semibold bg-white md:bg-opacity-50 text-adhocBlauw cursor-pointer rounded-[100px] border-solid border-adhocBlauw border-2 cursor-pointe duration-150 hover:bg-adhocBlauw hover:text-white">
            Offerte
          </li>
        </Link>
      </ul>
      <div className="md:hidden">
        <div onClick={handleMobileNav}>
          {mobileNav ? <AiOutlineClose size={30} /> : <AiOutlineMenu size={30} />}
        </div>
        <div
          className={
            mobileNav
              ? "fixed left-0 top-0 w-[60%] h-full bg-gray-50 drop-shadow-md"
              : "fixed left-[-100%]"
          }
        >
          <ul className="pt-8 text-center font-semibold text-[18px]">
            <Link to="/" onClick={closeMobileNav}>
              <li className="pb-4 text-adhocBlauw font-bold text-[28px]">
                <img className="w-[75px] mx-auto" src={Logo} alt="Ad hoc"/>
              </li>
            </Link>
            <li className="border-b">
              <p
                className="flex items-center justify-center gap-1 py-4"
                onClick={() => setserviceDropdown(!serviceDropdown)}
              >
                Services
                <span className={serviceDropdown ? "rotate-180" : ""}>
                  <BsChevronDown />
                </span>
              </p>
              <ul
                className={
                  serviceDropdown
                    ? "font-normal text-[16px] bg-gray-100 w-full"
                    : "hidden"
                }
              >
                <Link
                  to="services/software-ontwikkelen"
                  onClick={closeMobileNav}
                >
                  <li className="py-3 border-t border-b">
                    Software development
                  </li>
                </Link>
                <Link to="services/ict-servicedesk" onClick={closeMobileNav}>
                  <li className="py-3">ICT servicedesk</li>
                </Link>
                <Link
                  to="services/professioneel-advies"
                  onClick={closeMobileNav}
                >
                  <li className="py-3 border-t">Cybersecurity Consultancy</li>
                </Link>
              </ul>
            </li>
            <Link to="/over" onClick={closeMobileNav}>
              <li className="p-4 border-b">Over ons</li>
            </Link>
            <Link to="/contact" onClick={closeMobileNav}>
              <li className="p-4 border-b">Contact</li>
            </Link>
            <Link to="/offerte" onClick={closeMobileNav}>
              <li className="p-4">Offerte</li>
            </Link>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Navbar;
