import MessageForm from "../components/MessageForm";
import Fade from "react-reveal/Fade";
import { HiPhone } from "react-icons/hi";
import { HiMail } from "react-icons/hi";
import Contact from "../resources/contact/contact.jpeg";

function ContactPage() {
  return (
      <Fade>
        <div className="grid-cols-2 mx-4 mb-12 xl:grid gap-36">
          <div className="flex flex-col justify-between lg:col-span-1 xl:ml-auto">
            <div className="p-4">
              <h1 className="text-center md:text-left font-bold text-[30px] md:text-[32px] lg:text-[36px] text-adhocBlauw">
                Contact
              </h1>
              <p className="text-[16px] md:text-[18px] lg:text-[20px]">
                Wij zijn via de mail en telefonisch bereikbaar.
                <br /> Ook kunt u een bericht sturen via het formulier op deze
                pagina.
              </p>
            </div>

            <div className="flex items-end py-4 mt-8 shadow-md justify-evenly rounded-xl">
              <div>
                <h1 className="flex justify-center font-semibold text-[18px] lg:text-[20px]">
                  <a href="mailto: service@adhocit.nl">
                    <HiMail className="mt-0.5 lg:mt-1 mr-2" size={24} />{" "}
                  </a>
                  E-mail
                </h1>
                <h1 className="text-center text-slate-800 font-bold text-[18px] lg:text-[20px]">
                  service@adhocit.nl
                </h1>
              </div>

              <div>
                <h1 className="flex justify-center font-semibold text-[18px] lg:text-[20px]">
                  <HiPhone className="mt-0.5 lg:mt-1 mr-1" size={24} />
                  Telefoon
                </h1>
                <h1 className="text-center text-slate-800 font-bold text-[18px] lg:text-[20px]">
                  +31 6 20503666
                </h1>
              </div>
            </div>

            <div className="flex justify-center mt-10" >
              <img className="rounded-xl w-[600px]" src={Contact} alt="" />
            </div>
          </div>

          <div className="flex justify-center col-span-1 mt-10 lg:justify-start xl:mt-0 lg:mx-0">
            <MessageForm />
          </div>
        </div>
      </Fade>
  );
}

export default ContactPage;
