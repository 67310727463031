import { useState } from "react";
import MessageSucces from "./MessageSucces";

function MessageForm() {
  const [voornaam, setVoornaam] = useState("");
  const [achternaam, setAchternaam] = useState("");
  const [email, setEmail] = useState("");
  const [bericht, setBericht] = useState("");

  const [voornaamError, setVoornaamError] = useState(false);
  const [achternaamError, setAchternaamError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [berichtError, setBerichtError] = useState(false);

  const [formFilled, setFormFilled] = useState(false);

  function handleFormSubmit() {
    const setError = (errorState, value) => {
      errorState(value);
      errorValue = true;
    };

    let errorValue = false;

    !voornaam.length > 0
      ? setError(setVoornaamError, true)
      : setVoornaamError(false);
    !achternaam.length > 0
      ? setError(setAchternaamError, true)
      : setAchternaamError(false);
    !email.length > 0 ? setError(setEmailError, true) : setEmailError(false);
    !bericht.length > 0
      ? setError(setBerichtError, true)
      : setBerichtError(false);

    if (!errorValue) {
      sendOfferte();
      setFormFilled(true);
    }
    console.log(bericht);
  }

  function getData() {
    return {
      subject: "Bericht van " + voornaam + " " + achternaam,
      body:
        "<h1>Bericht</h1> <p>Naam: <span>" +
        voornaam +
        " " +
        achternaam +
        "<span><p/> <p>E-mail: <span>" +
        email +
        "<span><p/><p>Bericht: <span>" +
        bericht +
        "<span><p/>",
    };
  }

  const sendOfferte = () => {
    let data = getData();

    fetch("https://adhocmailer.herokuapp.com/Email", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
  };

  if (formFilled) {
    return <MessageSucces email={email} name={voornaam + " " + achternaam} message={bericht}/>;
  }

  return (
    <div className="w-full p-4 mx-auto bg-white rounded-lg shadow-lg md:w-fit xl:mx-0 md:p-8 md:px-10 lg:p-8 lg:px-12 xl:p-10 xl:px-16">
      <h1 className="text-center font-bold text-slate-700 text-[24px] lg:text-[30px] mb-8">
        Contact formulier
      </h1>

      <div className="text-center md:w-1/2">
        <div className="items-baseline justify-center block gap-1 md:flex">
          <label className="font-semibold text-[18px] lg:text-[20px]">
            E-mailadres*
          </label>
          {emailError ? (
            <label className="block text-red-500">(verplicht)</label>
          ) : null}
        </div>
        <input
          className="block p-2 mx-auto mt-1 border-2 border-gray-400 rounded-lg md:mx-0"
          type="email"
          placeholder="Uw e-mailadres"
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
          }}
        />
      </div>

      <div className="gap-8 mt-4 text-center md:flex">
        <div className="mb-4 md:mb-0">
            <div className="items-baseline justify-center block gap-1 md:flex">
              <label className="font-semibold text-[18px] lg:text-[20px]">Voornaam*</label>
              {voornaamError ? (
                <label className="block text-red-500">(verplicht)</label>
              ) : null}
            </div>
          <input
            className="block w-auto p-2 mx-auto mt-1 border-2 border-gray-400 rounded-lg md:mx-0"
            id="firstname"
            type="text"
            placeholder="Uw voornaam"
            value={voornaam}
            onChange={(e) => {
              setVoornaam(e.target.value);
            }}
          />
        </div>

        <div>
          <div className="items-baseline justify-center block w-auto gap-1 md:flex">
            <label className="font-semibold text-[18px] lg:text-[20px]">
              Achternaam*
            </label>
            {achternaamError ? (
              <label className="block text-red-500">(verplicht)</label>
            ) : null}
          </div>
          <input
            className="block p-2 mx-auto mt-1 border-2 border-gray-400 rounded-lg md:mx-0"
            id="lastname"
            type=""
            placeholder="Uw achternaam"
            value={achternaam}
            onChange={(e) => {
              setAchternaam(e.target.value);
            }}
          />
        </div>
      </div>

      <div className="pt-8 text-center">
        <div className="items-baseline justify-center gap-1 md:flex">
          <label className="font-semibold text-[18px] lg:text-[20px]">
            Bericht*
          </label>
          {berichtError ? (
            <label className="block text-red-500">(verplicht)</label>
          ) : null}
        </div>
        <textarea
          className="block w-full p-2 mt-1 border-2 border-gray-400 rounded-lg resize-none h-52"
          placeholder="Uw bericht..."
          value={bericht}
          onChange={(e) => {
            setBericht(e.target.value);
          }}
        />
      </div>

      <div className="flex flex-col mt-8">
        <button
          className="w-fit mx-auto text-white font-semibold py-2 px-10 rounded-[100px] bg-gradient-to-br from-adhocBlauw  via-adhocBlauw200  to-adhocDonkerBlauw bg-size-200 bg-pos-0 hover:bg-pos-100 transition-all duration-300"
          onClick={handleFormSubmit}
        >
          Verzenden
        </button>
      </div>
    </div>
  );
}

export default MessageForm;
