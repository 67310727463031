import { FaLinkedin } from "react-icons/fa";

function Persoon(props) {
  return (
    <>
      <div className="items-center gap-10 p-8 transition duration-500 ease-in-out bg-white shadow-lg sm:p-10 lg:flex rounded-xl md:mx-auto md:hover:scale-105">
        <div>
          <img
            className="rounded-[500px] mb-8 mx-auto"
            src={props.pic}
            width={250}
            alt={props.name}
          />
          <h1 className="text-lg font-bold text-center">{props.name}</h1>
          <h1 className="text-md font-semibold text-center">
            {props.function}
          </h1>
          <a href={props.link} target="_blank" rel="noreferrer">
            <FaLinkedin
              className="mx-auto mt-1 mb-4 transition ease-in-out cursor-pointer md:hover:scale-110"
              size={30}
            />
          </a>
        </div>
        <div className="max-w-xl mx-auto my-auto leading-8 text-left md:text-left">
          <p>{props.text}</p>
        </div>
      </div>
    </>
  );
}

export default Persoon;
